// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import $ from "jquery";

import { customAlphabet } from 'nanoid';

// v9 compat packages are API compatible with v8 code
// ver eta guia para aprovechar v9 en modularidad y cloud functions
// https://firebase.google.com/docs/web/modular-upgrade

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, listAll, uploadBytes, getDownloadURL, uploadBytesResumable, list } from "firebase/storage";



import { getFirestore, enableIndexedDbPersistence, collection, doc, query, where, getDocs, getDoc, QuerySnapshot, DocumentData } from "firebase/firestore";

import { initializeApp } from "firebase/app"


import { Subject, BehaviorSubject, bindCallback, Observable } from 'rxjs';


import { Service } from './service'
import { Utils } from './utils'


import { User, UserI } from '../models/user'
import { MyState } from "./state";



export const firebaseConfig = {
    apiKey: "AIzaSyBtjuJGM30UW_k5YG6MPzsUoriwy81DTW8",
    authDomain: "crcasas2018.firebaseapp.com",
    databaseURL: "https://crcasas2018.firebaseio.com",
    projectId: "crcasas2018",
    storageBucket: "crcasas2018.appspot.com",
    messagingSenderId: "385260406538",
    appId: "1:385260406538:web:834856e082171fa8ef5cf3",
    measurementId: "G-062YJ2FCKY"   
  };

  export type TourT = { panorama: string,  panoramaId: string, caption: string, defaultLat: number, defaultLong: number };
  type PanoT = { panoId: string; caption: string, defaultLat: number, defaultLong: number};
  type MarkerT = { markerId: string;  latitude: number; longitude: number;  };


  const nanoid = customAlphabet('abcdefghijklmnoprstuvwxyz', 4)


  export class MyFirebase {
    private static instance: MyFirebase;
    private _localExRate: number = 613;

    service: any;
    utils: any;
    user:User = User.getInstance();

    userUid$ = new Subject<string>();
    displayName$ = new BehaviorSubject<string>('');    

    signedIn$ = new BehaviorSubject<boolean>(false); // 0 is the initial value
    loadingUI$ = new BehaviorSubject<boolean>(false);

    defaultApp: any;
    firestore:any;
    state:any;

    constructor() {

      this.defaultApp = initializeApp(firebaseConfig);
      console.log(getAuth(this.defaultApp));



      try {
        this.firestore = getFirestore(this.defaultApp);
      }
      catch(e) {
        console.log(e);
      }     
      
      
      

      enableIndexedDbPersistence(this.firestore)
      .catch((err) => {
          if (err.code == 'failed-precondition') {
              console.log('failed-precondition')
              // Multiple tabs open, persistence can only be enabled
              // in one tab at a a time.
              // ...
          } else if (err.code == 'unimplemented') {
              console.log('unimplemented')
              // The current browser does not support all of the
              // features required to enable persistence
              // ...
          }
      });

      

      this.service = Service.getInstance();
      this.utils = Utils.getInstance();



      this.getFBLocalExRate()

      this.initApp();

      this.state = MyState.getInstance();

      
    }



    public static getInstance(): MyFirebase {
      if (!MyFirebase.instance) {
        MyFirebase.instance = new MyFirebase();
      }

      return MyFirebase.instance;
    }

    getUser(uid:string):Observable<any> {
      const observable = bindCallback($.getJSON);
      const result = observable( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${uid}`)
      return result;
    }

    private onSignedIn(authResult:any) {
      console.log(authResult)
      this.userUid$.next( authResult.uid);
      this.signedIn$.next( true );
    }

    private onSignedOut() {
      
    }

    // https://firebase.google.com/docs/firestore/query-data/get-data
    async getFBLocalExRate() {

      

      const docRef = doc(this.firestore, "currencyRates", "CRC");      
      const docSnap  = await  getDoc(docRef);

      console.log(docSnap.data().factor )
      
      this._localExRate = (docSnap.exists()) ? docSnap.data().factor : 1;

    }

    get localExRate(): number {
      return this._localExRate;
  }


/**
 * Initializes the app.
 */
initApp() {
  

}

signOut() {
  firebase.auth().signOut(); 
}

pauseAd(uid:string, forSale: boolean, forRent:boolean) {
  const today = new Date()
  console.log(forSale);
  firebase.firestore().collection('tasks/expire/ad').add({
                                                            uid: uid, 
                                                            forSale: forSale, 
                                                            forRent: forRent, 
                                                            dateTsCreated: today, 
                                                            action:'P',
                                                            pausedBy: 'U'
                                                          }).then( (u) => console.log(u))

  //firebase.firestore().collection('users').doc('BQH6MOifAdZ0DgbmfM9aZOfwNBk1').get().then(
  //  (u) => console.log(u.data())
  //)  

}

getCrime  (  crime:string) {
  return new Promise(resolve => {
      var crimeArray:any[] = [];
      var db = firebase.firestore();   

      var popRef = db.collection("data/crime/"+crime)

      popRef.get().then((querySnapshot) => {
          querySnapshot.forEach((crimeDoc) => {
              var crimeDocData = crimeDoc.data()
              crimeArray[crimeDocData.code] = crimeDocData;       
          })        
          resolve(crimeArray);  
      }) 
              
  })
}

renewAd(uid:string, forSale: boolean, forRent:boolean) {
  const today = new Date()
  firebase.firestore().collection('tasks/expire/ad').add(
                            { uid: uid, 
                              forSale: forSale, 
                              forRent: forRent, 
                              dateTsCreated: today, 
                              action:'R'
                            }
                          ).then(
    (u) => console.log(u)
  )

}

  getProperty(uid:string):any {
    const promise = $.getJSON(`https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/properties/${uid}`);
    return promise;
  }

getProperties(uid: string):any {
 
  const promise =  $.getJSON( `https://us-central1-crcasas2018.cloudfunctions.net/webapi/api/v1/users/${uid}/properties/expired` ) 
    return promise;
  }  


  async getTour(tourId:string): Promise<TourT>  {   // "CArIuxXVkWbKhTITGcdX"
    const docRef = doc(this.firestore, "virtualTours", tourId);      
    const docSnap  = await  getDoc(docRef);
    const tour = docSnap.data();
    return { panorama: tour.panorama, panoramaId: tour.panoramaId, caption: tour.caption, defaultLat: tour.defaultLat, defaultLong: tour.defaultLong }
  } 


  async getPano(tourId:string, panoId: string): Promise<PanoT>   {
    const docRef = doc(this.firestore, `virtualTours/${tourId}/panoramas`, panoId);      
    const docSnap  = await  getDoc(docRef);
    const pano = docSnap.data();
    return { panoId: pano.panorama, caption: pano.caption, defaultLat: pano.defaultLat, defaultLong: pano.defaultLong }
  }

  async getMarker(tourId:string, panoId: string, markerId:string): Promise<MarkerT>   {
    const docRef = doc(this.firestore, `virtualTours/${tourId}/panoramas/${panoId}/markers`, markerId);      
    const docSnap  = await  getDoc(docRef);
    const marker = docSnap.data();

    return { markerId: marker.markerId,  latitude: marker.latitude, longitude: marker.longitude  }

  }  



  async getPanos2(tourId:string, panos: PanoT[])  {
    const querySnapshot = await getDocs(collection(this.firestore, `virtualTours/${tourId}/panoramas`));


    querySnapshot.forEach( (doc) => {
      let e = {} as PanoT;
      e.panoId = doc.id;
      e.caption = doc.data().caption;
      e.defaultLat = doc.data().defaultLat;
      panos.push(e);

    });

  }

  async getPanos(tourId:string): Promise<PanoT[]>  {
    let list: PanoT[] = [];    
    const querySnapshot = await getDocs(collection(this.firestore, `virtualTours/${tourId}/panoramas`));
    querySnapshot.forEach( (doc) => {
      let e = {} as PanoT;
      e.panoId = doc.id;
      e.caption = doc.data().caption;
      e.defaultLat = doc.data().defaultLat;
      list.push(e);

    });
    return list;
  }  
  
  async getMarkers2(tourId:string, panoId: string, markers: MarkerT[]) {
    const querySnapshot = await getDocs(collection(this.firestore, `virtualTours/${tourId}/panoramas/${panoId}/markers`));
    querySnapshot.forEach((doc) => {
      let e = {} as MarkerT;
      e.markerId = doc.id;
      e.latitude = doc.data().latitude;
      e.longitude = doc.data().longitude;
      markers.push(e);
    });
  }   

  async getMarkers(tourId:string, panoId: string): Promise<MarkerT[]>  {
    let list: MarkerT[] = [];
    const querySnapshot = await getDocs(collection(this.firestore, `virtualTours/${tourId}/panoramas/${panoId}/markers`));
    querySnapshot.forEach((doc) => {
      let e = {} as MarkerT;
      e.markerId = doc.id;
      e.latitude = doc.data().latitude;
      e.longitude = doc.data().longitude;
      list.push(e);
    });

    return list;

  } 

  upload(file:any, fileName: string, progress: Function ) {

    const uid = this.state.state.uid;
    const random  = nanoid();
    const storage = getStorage(this.defaultApp);
    const storageRef = ref(storage, `tours/${uid}/vt001/${random}-${fileName}`);
    
    // 'file' comes from the Blob or File API
    const uploadTask = uploadBytesResumable(storageRef, file)
    
  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on('state_changed', 
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progressStatus = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progressStatus + '% done');
      progress(true,snapshot.bytesTransferred,snapshot.totalBytes );
      console.log(snapshot.state);
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running':
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // Handle unsuccessful uploads
    }, 
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    }
  );    

    return uploadTask

  }

  listAllFiles():Subject<string> {

    const storage = getStorage(this.defaultApp);
    const uid = this.state.state.uid;
    console.log(uid)
    const listRef = ref(storage, `tours/${uid}/vt001`); //${uid}
    const downloadURL$ = new Subject<string>();
    // Find all the prefixes and items.
    console.log('listing all files');
    list(listRef)
      .then((res) => {
        console.log(res);
        console.log('res')
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
          console.log(folderRef)
        });
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          console.log(itemRef)
          getDownloadURL(itemRef).then((downloadURL) => {
            //console.log('File available at', downloadURL);
            downloadURL$.next(downloadURL)
          });
        
        });
      }).catch((error) => {
        console.log(error)
      });
    
    return downloadURL$

  }

}  
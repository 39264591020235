import { AbstractButton } from 'photo-sphere-viewer';
import CustomPlugin from './custom-plugin';


import $ from 'jquery';
import { S3Upload } from '../s3/s3';

const s3Upload = new S3Upload();

export class CustomButton extends AbstractButton {
  
  static id = 'custom-button';
  static icon = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.76 217.88"><path d="M851.64,539C850,483.18,898.32,433.16,954.87,430.66c60.75-2.68,110.74,44,114.28,102.31,3.83,62.87-46.49,113.83-105.68,115.43C903.21,650,851.06,601.26,851.64,539ZM946.2,519.4v6.41q0,35.49,0,71c0,4.54.73,8.9,5,11.27,5.73,3.17,12,2.93,17.79.43,5.33-2.3,5.86-7.51,5.84-12.76-.06-23.33,0-46.65,0-70v-6.16c1.94,1.91,3.11,3,4.22,4.16,8.77,9.14,17.49,18.32,26.3,27.42,5.7,5.89,11.4,5.87,17.32.1a54.71,54.71,0,0,0,4.16-4.32c3.53-4.29,3.57-10.08-.37-14.06q-29.19-29.46-58.65-58.69c-4.51-4.47-10.18-4.36-14.72.14q-29.29,29-58.34,58.29A10.07,10.07,0,0,0,892,543.19c3.63,12.32,16.37,15.61,25.11,6.5Z" transform="translate(-851.6 -430.55)"/></svg>';
  static iconActive = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.76 217.88"><defs><style>.cls-1{fill:#00ff7f;}</style></defs><path class="cls-1" d="M622.19,518.46c-3.55-58.3-53.54-105-114.29-102.3-56.54,2.5-104.91,52.52-103.23,108.36-.58,62.23,51.57,111,111.84,109.37C575.69,632.3,626,581.34,622.19,518.46Zm-42.36,14a56.5,56.5,0,0,1-4.16,4.32c-5.91,5.77-11.62,5.78-17.32-.11-8.81-9.09-17.53-18.27-26.29-27.41-1.11-1.16-2.29-2.25-4.23-4.16v6.16c0,23.33,0,46.65,0,70,0,5.24-.51,10.46-5.84,12.76-5.79,2.5-12.07,2.74-17.8-.44-4.27-2.36-5-6.73-5-11.26q0-35.49,0-71V504.9c-10.28,10.7-19.69,20.49-29.09,30.29-8.74,9.1-21.48,5.82-25.11-6.51a10.07,10.07,0,0,1,2.71-10.55q29.13-29.19,58.34-58.29c4.54-4.5,10.21-4.61,14.73-.14q29.48,29.18,58.64,58.69C583.4,522.36,583.36,528.15,579.83,532.45Z" transform="translate(-404.63 -416.05)"/></svg>';

  constructor(navbar:any) {
    super(navbar, 'psv-button--hover-scale psv-markers-button', true);
    
    // do your initialisation logic here
    // you will probably need the instance of your plugin
    this.plugin = this.psv.getPlugin(CustomPlugin.id);
    if (this.plugin) {
      this.toggleActive(true);
    }
  
    console.log('llego aca..');


    this.show()

  }
  
  destroy() {
    // do your cleanup logic here
    
    super.destroy();
  }

  onClick() {
    console.log('on lcick');
    this.toggleActive();

    this.psv.panel.show({
      id     : 'fieList',
      content: CustomPlugin.MARKERS_LIST_TEMPLATE('Tituloo'),
    });


    

    $("#upload2").on('change', null, (e) => {
      let input = <HTMLInputElement>e.target;
      let files = input.files;
      let f = files[0];
      let name = f.name;
      let type = f.type;
      console.log("File name: " + name);
      console.log("Type: " + f.type);
  
      s3Upload.getURL(name, type,  (err:any,url:any) =>{
          console.log(url);
          s3Upload.uploadFile(url, f, type) 
      })
  
  });  
    



  }
  
  
}
import { AbstractButton } from 'photo-sphere-viewer';


export class RemoveButton extends AbstractButton {
  
  static id = 'remove-button';
  static icon = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.87 243.46"><path d="M608.32,434.49c-160.16,2.79-159.69,240.75-.07,243.46C768.28,675,768.17,437.36,608.32,434.49ZM584.86,575.73H519.5V533h65.07l46.95-.33H697.1v42.83H631.55Z" transform="translate(-488.37 -434.49)"/></svg>';
  static iconActive = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.87 243.46"><defs><style>.cls-1{fill:#00ff7f;}</style></defs><path class="cls-1" d="M608.32,434.49c-160.16,2.79-159.69,240.75-.07,243.46C768.28,675,768.17,437.36,608.32,434.49ZM584.86,575.73H519.5V533h65.07l46.95-.33H697.1v42.83H631.55Z" transform="translate(-488.37 -434.49)"/></svg>';


  constructor(navbar:any) {
    super(navbar, 'psv-button--hover-scale psv-markers-button', true);
    

    this.show()

  }
  
  destroy() {
    // do your cleanup logic here
    
    super.destroy();
  }

  onClick() {
    console.log('on lcick');
    this.toggleActive();

  }
  
  
}

interface StateI {
    loggedout: boolean;
    loggedin: boolean;
    uid: string;    
    displayName: string ; 
    userPic: string;
    redirectUrl: string;
    previews: string[];
    images: string[];

}

const HIDE_CLASS = 'd-none';

export class MyState  {
    private static instance: MyState;
    state: any;
    _state:StateI = {
        loggedin: false,
        loggedout: true,        
        uid: null,
        displayName: null,
        userPic: null, // photoURL: "https://lh3.googleusercontent.com/a-/AAuE7mCMs8c944u8L6CMXuDEsxs3ouvmuBGzb1cxTXGLkHE"
        redirectUrl: null,
        previews: new Array<string>(),
        images: new Array<string>()        
    };   

    constructor() {
        let handler = {         
            set: this.handler
        };

        this.state = new Proxy(this._state, handler);

    }

    public static getInstance(): MyState {
        if (!MyState.instance) {
            MyState.instance = new MyState();
        }
  
        return MyState.instance;
      }  
    
    handler(obj:any, prop:string, value:any) {
               // The default behavior to store the value
               obj[prop] = value;
               const selector = `[data-binding="${prop}"]`;
               const elements = document.querySelectorAll(selector);

               elements.forEach(function(element) {
                    const attr = element.getAttribute('data-binding-attr');               
                    const display = element.getAttribute('data-binding-display');
                    
                    if (attr) element.setAttribute(attr, value)
                    if (display) {
                        if (display=='on') {
                            if (value) element.classList.remove(HIDE_CLASS)
                            else element.classList.add(HIDE_CLASS)
                        }
                        if (display=='off') {
                        if (value) element.classList.add(HIDE_CLASS)
                        else element.classList.remove(HIDE_CLASS)                       
                        }                   
                    }
                    if (!attr && !display) element.innerHTML = value;                    
    
               });

 
               return true;    

    }

        
}
/* 
 ojo https://photoswipe.com/   esta precioso para galleria!!
 https://github.com/sjansen/browser-based-s3-upload-demo

https://<bucket-name>.s3.amazonaws.com/<key>

*/

import $ from 'jquery';
import * as AWSx from 'aws-sdk';
var AWS = AWSx as any;

const bucket = 'virtual-tours-photos';
const region = 'us-east-1';
const key = 'users/user1/';

export class S3Upload {

    public contents: any[]

    constructor() {

      AWS.config.update({
        accessKeyId: 'AKIAS6LYDNYCETNFBR55', 
        secretAccessKey: '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', 
        region: region, 
        signatureVersion: 'v4',
      });      

  }


  getURL(fileName: any, type: any, callback: any) {


    let options = {
      signatureVersion: 'v4',
      region: region, // same as your bucket
      endpoint: new AWS.Endpoint(bucket + '.s3-accelerate.amazonaws.com'),    useAccelerateEndpoint: true,  
    }

    let client = new AWS.S3(options); 

    const params = {
      Bucket: bucket,
      Key: key+fileName,
      Expires: 30 * 60, // 30 minutes
      ContentType: type,
      ACL: 'public-read'
   };

   client.getSignedUrl('putObject', params, callback)

  }

   uploadFile(url: any, file: any, type: any) {
    $.ajax({
      type: "PUT",
      url: url,
      data: file, // serializes the form's elements.
      contentType: type,
      processData: false,
      success: function(data, status, xhr)
      {
          console.log(data);
          console.log(status);
          console.log(xhr);
          $('p#status').append('status: ' + status + ', data: ' + data);
      },
      error: function (xhr, ajaxOptions, thrownError) {
          console.log(xhr);
       $('p#status').append('xhr: ' + xhr + ', ajaxOptions: ' + ajaxOptions+ ', thrownError: ' + thrownError);
     }           
    });   
   }

// http://gabefuentes.com/static/2016/10/15/s3-photo-album.html Delimiter: '/', ,  StartAfter: 'users/user1' , Prefix: "users/user1" 
getImages() {
  var s3 = new AWS.S3();
  var trips = { Bucket: "virtual-tours-photos-resized", Prefix: "resized-users/user1"}
  s3.listObjectsV2(trips, function(err:any, data:any) {
    if(err) {
      console.log(err, err.stack)
    } else {
      this.contents = data.Contents
      console.log(data)
      console.log(this.contents)
    }
  })
}


getImagesPromise() {
  var s3 = new AWS.S3();
  var trips = { Bucket: "virtual-tours-photos-resized", Prefix: "resized-users/user1"}
  return new Promise((resolve, reject) => {
    s3.listObjectsV2(trips, function(err:any, data:any) {
      if(err) {
        reject(err)
      } else {
        this.contents = data.Contents
        resolve(data.Contents)
      }
    })
  })
}


deleteObject(bucket:any, key:any) {
  var s3 = new AWS.S3();
  var params = {  Bucket: bucket, Key: key };
  
  s3.deleteObject(params, function(err:any, data:any) {
    if (err) console.log(err, err.stack);  // error
    else     console.log('deleted');                 // deleted
  });
}



}


// const PhotoSphereViewer= require('photo-sphere-viewer');

import PhotoSphereViewer = require('photo-sphere-viewer');
import MarkersPlugin = require('photo-sphere-viewer/dist/plugins/markers');
import { MyFirebase, TourT } from '../firebase';
// import * as MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
import  Bind  from 'bind.js'
import { registerButton } from 'photo-sphere-viewer';
import CustomPlugin from './custom-plugin';
import  { AddButton } from './add-button';
import  { RemoveButton } from './remove-button';

// import { nanoid } from 'nanoid'
import { customAlphabet } from 'nanoid'


import { Subject } from 'rxjs';

registerButton(AddButton);
registerButton(RemoveButton);

export class Viewer {

    container: string = '#viewer';
    panorama: string = 'https://virtual-tours-photos.s3.amazonaws.com/users/tour1/360_0237_pano.jpg';
    caption: string = 'CRCASAS.com';
    defaultLat: number = 0.3;   
    viewer: any;  
    markersPlugin: any;
    customPlugin: any;
    fb:any;
    tourId:string = "CArIuxXVkWbKhTITGcdX";

    markerSelected$ = new Subject<string>();
    markerSelected: string = null;

    markerRemoved$ = new Subject<string>();    

    constructor() {
        this.fb = MyFirebase.getInstance();
        this.createViewer(this.tourId);

       
    }

    async createViewer(tourId: string) {


      let self = this;

      const tour = await this.fb.getTour(tourId);
      const pano = await this.fb.getPano(tourId, tour.panoramaId);
      const panos = await this.fb.getPanos(tourId);      
      const markers = await this.fb.getMarkers(tourId, tour.panoramaId);  


      const markers2 = markers.map( (item:any) => {
        const container =                     {
                    // circle marker
                    id: 'circle',
                    longitude: item.longitude,
                    latitude: item.latitude,
                    width: 32,
                    height: 32,                      
                    tooltip: 'Seguir',                    
                    image: 'img/panos/3d-arrow.png',
                    anchor: 'bottom center',
                    data: {
                      generated: true
                    }                      
                  }   ;   
    
        return container;
    })
   


        this.viewer = new PhotoSphereViewer.Viewer({  
          navbar: [
            'custom-button',
            'add-button',    
            'remove-button',                       
            //'left-button',
            //'right-button',                        
            'caption',
            'fullscreen'
            ],                
            container: document.querySelector(this.container),
            panorama: tour.panorama,
            caption: tour.caption,
            defaultLat: tour.defaultLat,  
            defaultLong: tour.defaultLong,              
            plugins: [
                [CustomPlugin, {                  
                }],                 
                [ MarkersPlugin, {
                  // list of markers
                  markers: markers2
                }]
              ]      
          });

          this.markerSelected$.subscribe(
            (markerId) => { 
              console.log(markerId);
              console.log(this.markerSelected);

              if (this.markerSelected && (this.markerSelected != markerId))   {
                this.markersPlugin.updateMarker({
                  id: this.markerSelected,
                  image: 'img/panos/addphoto.png'
                });                 
              }  

              this.markersPlugin.updateMarker({
                id: markerId,
                image: 'img/panos/addphotoselected.png'
              });    
     
              (<HTMLInputElement>document.getElementById("formMarkerId")).value = markerId;

              this.markerSelected = markerId 
            }
          )

          this.markerRemoved$.subscribe(
            (markerId) => { 
              console.log('This marker was removed: '+ markerId)
              if (this.markerSelected == markerId ) {
                (<HTMLInputElement>document.getElementById("formMarkerId")).value = '';
                this.markerSelected = null;
              }  
            }
          )


          this.viewer.on('click', (e:any, data:any) => {
            console.log(`${data.rightclick ? 'right clicked' : 'clicked'} at longitude: ${data.longitude} latitude: ${data.latitude}`);
          });
          
          this.markersPlugin = this.viewer.getPlugin(MarkersPlugin);

          //this.customPlugin = this.viewer.getPlugin(CustomPlugin);

          this.viewer.on('select-marker', (marker:any) => {
            console.log('this.viewer.on select-marker');
            if (marker.id === 'goto-pano') {
              self.viewer.setPanorama('img/panos/360_0269_pano.jpg', null, true);
            }
            // self.viewer.setPanorama('https://virtual-tours-photos.s3.amazonaws.com/users/tour1/360_0238_pano.jpg', null, true);
          });
        
          /**
           * Delete a generated marker when the user double-clicks on it
           * Or change the image if the user right-clicks on it
           */
          this.markersPlugin.on('select-marker', function(e:any, marker:any, data:any) {
            const markerId = marker.id; 
            console.log('selected: ' + markerId)       
            if (data.dblclick) {
                self.markersPlugin.removeMarker( markerId);
                console.log('removed: ' + markerId)
                self.markerRemoved$.next(markerId); 
            } else self.markerSelected$.next( markerId);

          });


          this.markersPlugin.on('unselect-marker', function(e:any, marker:any, data:any) {  
            console.log('unselect-marker');        
            //self.markersPlugin.updateMarker({
            //  id: marker.id,
            //  image: 'img/panos/addphoto.png'
            //});

          });          
        

        
          this.viewer.on('click', function(e:any, data:any) {
            const nanoid = customAlphabet('abcdefghijklmnoprstuvwxyz', 6)
          
            const id = '#' + nanoid();

            self.markersPlugin.addMarker({
              id: id,
              longitude: data.longitude,
              latitude: data.latitude,
              image: 'img/panos/addphoto.png',
              width: 32,
              height: 32,
              anchor: 'bottom center',
              tooltip: 'Generated pin',
              data: {
                generated: true,
                selected: true
              }
            });
 
          });

     

         

    }



}


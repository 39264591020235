import { MyFilepond } from "../main/filepond/filepond";
import { Viewer } from "../main/tour/psv";

  export class TourPage {


    constructor() {
      console.log('tourpage')
      const viewer = new Viewer();  
      const filepond = new MyFilepond();     

    }


}




import Navigo from "navigo";
import { MyAuth } from "../main/authentication/auth";
import { MyFirebase } from "../main/firebase";
import { MyState } from "../main/state";
import { Utils } from "../main/utils";
import { TourPage } from "./tourpage";



const utils = Utils.getInstance();

const auth = MyAuth.getInstance();

const firebase = MyFirebase.getInstance();

const PAGES_ID: {[key: string]: string} = {
  signin: '#page-signin',
  signup: '#page-signup',
  reset: '#page-reset',
  tour: '#page-tour',
  dropzone: '#page-dropzone'         
}




const ANIMATIONS = ['zoomIn','zoomInUp','slideInDown','fadeIn','fadeInDown','fadeInUp'];
const ANIM_NUM = 8;
  export class MainPage {
    private static instance: MainPage ; 
    router: any;

    constructor() {
      this.router = new Navigo('/');
      let tour = new TourPage();  
      let self = this;

      let state = MyState.getInstance();



      this.router.hooks({
        before(done:any, match:any) {
          console.log(match);
          if (match.url == 'tour' && state.state.loggedout) { 
                done(false); 
                state.state.redirectUrl = 'tour' ; 
                self.router.navigate('signin')}
          else done()
        },        
        leave(done:any, match:any) {
          let history = self.router.lastResolved();
          if (history.length >0  ) {
              let last = history[0].url;
              if (last) utils.hideEl(PAGES_ID[last])
              else utils.hideEl(PAGES_ID['signin'])                
          }
          console.log(self.router.lastResolved())
          done();
        }       
      });

      for (let key in  PAGES_ID) {
        let value =  PAGES_ID[key];
        console.log(value);
        this.router.on('/'+key, function () {

          utils.showEl(value);   

          let animationNumber = utils.getRandomInt(ANIM_NUM );      
          let animation = 'animate__' + ANIMATIONS[animationNumber ];      
          let element = document.querySelector(value);
          element.classList.add('animate__animated', animation); 
          setTimeout( function(){ element.classList.remove( 'animate__animated', animation); }, 2000 );                 
          
        },
        {
          before(done:any, match:any) {
            console.log(match)
            done();
          }      
        })          
      }   
  
      this.router.on( function () {
        // console.log(window.location)
        // let  currentLoc = self.router.getCurrentLocation().url;
        // let lastResolved = null;
        // if (self.router.lastResolved()) lastResolved  = self.router.lastResolved().url
        // if (!currentLoc) currentLoc = lastResolved;
        // if (currentLoc) utils.hideEl(PAGES_ID[currentLoc])
        utils.showEl(PAGES_ID['signin']);          
        let element = document.querySelector(PAGES_ID['signin']);
        let animationNumber = utils.getRandomInt(ANIM_NUM );      
        let animation = 'animate__' + ANIMATIONS[animationNumber ];         
        element.classList.add('animate__animated', animation); 
        setTimeout( function(){ element.classList.remove( 'animate__animated', animation); }, 2000 );                 
   
        
      });


      this.router.resolve();  
      
      auth.user$.subscribe(
        (u:any) => {
          if (u) {
            console.log(u);
            state.state.loggedin = true;
            state.state.loggedout = false;            
            state.state.displayName = u.displayName;
            state.state.userPic =  u.photoURL;  
            state.state.uid = u.uid;
            firebase.listAllFiles().subscribe(
              (du) =>  { 
                          if (du.includes('200x200')) state.state.previews.push(du)
                          else  state.state.images.push(du)
                        }
            );
            if (state.state.redirectUrl) self.router.navigate(state.state.redirectUrl);       
            }
          else  {state.state.loggedin = false; state.state.loggedout = true;  } ; 
        }
      )      

    }

    public static getInstance(): MainPage {
      if (!MainPage.instance) {
        MainPage.instance = new MainPage();
      }

      return MainPage.instance;
    }    

}

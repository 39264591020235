import { AbstractPlugin } from 'photo-sphere-viewer';
import { registerButton } from 'photo-sphere-viewer';
import { CustomButton } from './custom-button';
import { LeftButton } from './left-buttom';
import { RightButton } from './right-buttom';





// In your main plugin file call registerButton in your main plugin file. 
// This will only make the button available but not display it by default, 
// the user will have to declare it in its navbar configuration.

registerButton(CustomButton);

registerButton(LeftButton);

registerButton(RightButton);



export default class CustomPlugin extends AbstractPlugin {
  
  static id = 'custom-plugin';

  selected: any = null


  static MARKERS_LIST_TEMPLATE2 = (title:any) => `
  <div class="dropzone dz-clickable" id="dropzone">
    <div class="dz-message d-flex flex-column">
      <i class="material-icons text-muted">cloud_upload</i>
      Drag &amp; Drop here or click ${title}
    </div>
  </div> 
  `;


  static MARKERS_LIST_TEMPLATE = (title:any) => `
  <p>${title}</p>
  <div class="container col-lg-12 text-center  mt-5">
    <div id="s3Form"></div>
    <p id="status"></p>
    <div id="fileuploader">Upload</div>
    <input id="upload2" type="file"   name="upload2" />
  </div> 


  `;
  
  
  constructor(psv:any, options:any) {
 
    super(psv);
    
    console.log('customplugin construted');


  }
  
  destroy() {
    // do your cleanup logic here
    
    super.destroy();
  }




  public dragOver(e: any) {
    console.log('dragstart')
    if (this.isBefore(this.selected, e.target)) {
      e.target.parentNode.insertBefore(this.selected, e.target)
    } else {
      e.target.parentNode.insertBefore(this.selected, e.target.nextSibling)
    }
  }
  
  public dragEnd() {
    console.log('dragstart')
    this.selected = null
  }
  
  public  dragStart(e: any) {
    console.log('dragstart')
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/plain', null)
    this.selected = e.target
  }
  
  public  isBefore(el1: any, el2: any) {
    let cur
    if (el2.parentNode === el1.parentNode) {
      for (cur = el1.previousSibling; cur; cur = cur.previousSibling) {
        if (cur === el2) return true
      }
    }
    return false;
  }
  
}
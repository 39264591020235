import $ from "jquery";
import { fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

export class Utils {
    private static instance: Utils;

    public thousandSeparator: string = ',';
    public decimalSeparator: string = '.';
    public isMobile: boolean = true;




    private constructor() {
        this.thousandSeparator = this.getNumberSeparators().thousand;
        this.decimalSeparator = this.getNumberSeparators().decimal;    
        this.isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        console.log(this.isMobile)
        
    }

    public static getInstance(): Utils {
        if (!Utils.instance) {
            Utils.instance = new Utils();
        }

        return Utils.instance;
    }

    public hideEl(el:string) {
        $( el ).addClass( "d-none" );   
      }
    
      public showEl(el:string) {
        $( el ).removeClass( "d-none" ); 
    }

    getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
   }

    getPrice(ad:any):string {
        let result:string = '';
        
        if (!ad.priceSaleInt) ad.priceSaleInt = 0;
        if (!ad.priceSaleLocal) ad.priceSaleLocal = 0;
        if (!ad.priceRentInt) ad.priceRentInt = 0;
        if (!ad.priceRentLocal) ad.priceRentLocal = 0;        

        if (ad.priceCurrency=='USD') {
          if (ad.forSale) result = '$' + ad.priceSaleInt.toLocaleString('en')
          else result = '$' + ad.priceRentInt.toLocaleString('en')
        } else {
          if (ad.forSale) result =  '₡' +  ad.priceSaleLocal.toLocaleString('en')
          else result = '₡' + ad.priceRentLocal.toLocaleString('en')
        }
        return result
      }

      getShortPrice(ad:any):string {
        let result:string = '';
        
        if (!ad.priceSaleInt) ad.priceSaleInt = 0;
        if (!ad.priceSaleLocal) ad.priceSaleLocal = 0;
        if (!ad.priceRentInt) ad.priceRentInt = 0;
        if (!ad.priceRentLocal) ad.priceRentLocal = 0;  
 
        ad.priceSaleInt = Math.round(ad.priceSaleInt / 1000);
        ad.priceSaleLocal = Math.round(ad.priceSaleLocal / 1000000);
        ad.priceRentInt = Math.round(ad.priceRentInt) ;
        ad.priceRentLocal = Math.round(ad.priceRentLocal / 1000);          

        if (ad.priceCurrency=='USD') {
          if (ad.forSale) result = '$' + ad.priceSaleInt.toLocaleString('en') + 'K'
          else result = '$' + ad.priceRentInt.toLocaleString('en') 
        } else {
          if (ad.forSale) result =  '₡' +  ad.priceSaleLocal.toLocaleString('en') + 'M'
          else result = '₡' + ad.priceRentLocal.toLocaleString('en') + 'K'
        }
        return result
      }

      getShortDesc(ad:any):string {
        let result:string = ad.saleType;

        if (ad.propertyTypeName=='Residencial') {
            let desc = ''
            if (ad.numberOfRooms >0) desc = ad.numberOfRooms.toString() + ' do'; 
            if (ad.numberOfGarages >0) desc = desc + ' ' + ad.numberOfGarages.toString() + ' ga '; 
            if (ad.numberOfBaths >0) desc = desc + ' ' + ad.numberOfBaths.toString() + ' ba ';
            if (ad.numberOfFloors >0) desc = desc + ' ' + ad.numberOfFloors.toString() + ' pi ';
            result = desc + result;
        }
        return result
      }      

      bind(source:string, target: string, transform: Function) {
        if ($( `[data-field-source='${source}']`).first()) {
            let input = $( `[data-field-source='${source}']` ).first();
            fromEvent(input, 'change').pipe(
                map((ev:any) => ev.target.value ),
                map((ev:any) => ev  ),
            )   
            .subscribe(
                x => $( `[data-field-target='${target}']` ).each( (i,v) => {v.innerHTML =  transform(x) }) 
            )  
        }       
    }

    bindInput(source:string, target: string, continuos: boolean, transform: Function) {
        if ($( `[data-field-source='${source}']`).first()) {
            let input = $( `[data-field-source='${source}']` ).first();

            const inputEvents$ = fromEvent(input, 'input');
            const changeEvents$ = fromEvent(input, 'change');
            const allEvents$ = continuos ? merge(inputEvents$, changeEvents$  ) : changeEvents$;

            allEvents$.pipe(
                map((ev:any) => ev.target.value ),
                map((ev:any) => ev  ),
            )   
            .subscribe(
                x => $( `[data-field-target='${target}']` ).each( (i,v:HTMLInputElement) => {v.value =  transform(x) }) 
            )  
        }       
    }  
    
    bindInputByEl(source:Element, target: HTMLInputElement, continuos: boolean, transform: Function) {

            const inputEvents$ = fromEvent(source, 'input');
            const changeEvents$ = fromEvent(source, 'change');
            const allEvents$ = continuos ? merge(inputEvents$, changeEvents$  ) : changeEvents$;

            allEvents$.pipe(
                map((ev:any) => ev.target.value ),
                map((ev:any) => ev  ),
            )   
            .subscribe(
                x => target.value =  transform(x)  
            )  
       
    }  
    
    bindInputByElMask(source:Element, target: HTMLInputElement, continuos: boolean, transform: Function) {

        const inputEvents$ = fromEvent(source, 'input');
        const changeEvents$ = fromEvent(source, 'change');
        const allEvents$ = continuos ? merge(inputEvents$, changeEvents$  ) : changeEvents$;

        allEvents$.pipe(
            map((ev:any) => ev.target.value ),
            map((ev:any) => ev  ),
        )   
        .subscribe(
            x => transform(x)  
        )  
   
}    

    bindCheck(source:string, target: string, transform: Function) {
        if ($( `[data-field-source='${source}']`).first()) {
            let input = $( `[data-field-source='${source}']` ).first();
            fromEvent(input, 'change').pipe(
                map((ev:any) => ev.target.checked ),
                map((ev:any) => ev  ),
            )   
            .subscribe(
                x => $( `[data-field-target='${target}']` ).each( (i,v) => {v.innerHTML =  transform(x) }) 
            )  
        }       
    }    

    trigger(source:string,  transform: Function) {
        if ($( `[data-field-source='${source}']`).first()) {
            let input = $( `[data-field-source='${source}']` ).first();
            fromEvent(input, 'change').pipe(
                map((ev:any) => ev.target.value ),
                map((ev:any) => ev  ),
            )   
            .subscribe(        
                x =>   transform(x) 
            )  
        }       
    }    

    bindToValue(source:string, transform: Function) {
        if ($( `[data-field-source='${source}']`).first()) {
            let input = $( `[data-field-source='${source}']` ).first();
            fromEvent(input, 'change').pipe(
                map((ev:any) => ev.target.value ),
                map((ev:any) => ev  ),
            )   
            .subscribe(
                x => transform(x)  
            )  
        }       
    }      
    
    triggerValue(source:string,  transform: Function) {


            let inputList = $( `[data-field-source="${source}"]` );

            inputList.each((i,v) => {
                fromEvent(v, 'click').pipe(
                    map((ev:any) =>  {return {attr: ev.target.attributes['data-field-attr'].value,
                                              value: ev.target.attributes['data-field-value'].value}} ),
                    map((ev:any) => ev  ),
                )   
                .subscribe(        
                    x =>   transform(x) 
                ) 
            })   
    } 


    monthlyPayment(p:number, n:number, i:number):number {
        return p * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
      }
    

      getNumberSeparators() {
  
        // default
        var res = {
          "decimal": ".",
          "thousand": ","
        };
        
        // convert a number formatted according to locale
        var str = parseFloat('1234.56').toLocaleString();
        
        // if the resulting number does not contain previous number
        // (i.e. in some Arabic formats), return defaults
        if (!str.match("1"))
          return res;
        
        // get decimal and thousand separators
        res.decimal = str.replace(/.*4(.*)5.*/, "$1");
        res.thousand = str.replace(/.*1(.*)2.*/, "$1");
        
        // return results
        return res;
      }   
      
      displayNumber(n:number, s:string, d:number): string {
        const opts = {
            style: s,
            minimumFractionDigits: d,
            maximumFractionDigits: d
          }          
          return n.toLocaleString('en-US', opts)
      }

      updateStatus( e:any) {     
        if (navigator.onLine) {
            console.log('internet back')
        } else {
            console.log('internet lost')
        }
      }

    set(clone: Element, selector:string, value:string) {
        const e = (<Element>clone).querySelector(selector);
        e.innerHTML = value;
    }
    setAttr(clone: Element, attr:string, selector:string, value:string) {
        const e = (<Element>clone).querySelector(selector);
        e.setAttribute(attr, value);

    }      

}

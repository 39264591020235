import '../styles/style.scss';
import '../styles/custom.css';
import * as Bootstrap from 'bootstrap'
import { Utils } from '../app/main/utils';
import { MyFirebase } from '../app/main/firebase';
import { MyAuth } from '../app/main/authentication/auth';
import { MainPage } from '../app/pages/mainpage';

const utils = Utils.getInstance();
const firebase = MyFirebase.getInstance();
const myAuth = MyAuth.getInstance();
const mainPage = MainPage.getInstance();

console.log(Bootstrap.Tooltip.VERSION);


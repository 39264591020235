
import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import { MyFirebase } from '../firebase';

FilePond.registerPlugin(
    FilePondPluginFileMetadata, 
    FilePondPluginImageCrop,
    FilePondPluginImagePreview,
    FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateSize,    
  );

const firebase = MyFirebase.getInstance();  

export class MyFilepond {


    constructor() {
     
            const inputElement = document.querySelector('input[type="file"]');
            const pond = FilePond.create(inputElement);


            pond.setOptions({
                server: {
                    process: this.process
                },
            })
            

    }


    process(
        fieldName: string,
        file: any,
        metadata: { [key: string]: any },
        load: (p: string | { [key: string]: any }) => void,
        error: (errorText: string) => void,
        progress: any,
        abort: () => void
    ){
            // fieldName is the name of the input field
            // file is the actual file object to send
            console.log(file.name);

            const uploadTask = firebase.upload(file, file.name, progress);
            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button           
                    // Let FilePond know the request has been cancelled
                    uploadTask.cancel();
                    abort();
                },
            };

    };
}



type ProgressServerConfigFunction = (
    /**
     * Flag indicating if the resource has a length that can be calculated.
     * If not, the totalDataAmount has no significant value.  Setting this to
     * false switches the FilePond loading indicator to infinite mode.
     */
    isLengthComputable: boolean,
    /** The amount of data currently transferred. */
    loadedDataAmount: number,
    /** The total amount of data to be transferred. */
    totalDataAmount: number,
) => void;
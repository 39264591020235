import { AbstractButton } from 'photo-sphere-viewer';


export class AddButton extends AbstractButton {
  
  static id = 'add-button';
  static icon = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.87 243.46"><path d="M957.47,420.64c-160.15,2.78-159.69,240.74-.06,243.46C1117.44,661.15,1117.32,423.51,957.47,420.64Zm74,137.49H976.83V608H937.92V558.29H883.46V522.72h54.22V472.89H976.8v49.55h54.66Z" transform="translate(-837.52 -420.64)"/></svg>';
  static iconActive = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.87 243.46"><defs><style>.cls-1{fill:#00ff7f;}</style></defs><path class="cls-1" d="M957.47,420.64c-160.15,2.78-159.69,240.74-.06,243.46C1117.44,661.15,1117.32,423.51,957.47,420.64Zm74,137.49H976.83V608H937.92V558.29H883.46V522.72h54.22V472.89H976.8v49.55h54.66Z" transform="translate(-837.52 -420.64)"/></svg>';


  constructor(navbar:any) {
    super(navbar, 'psv-button--hover-scale psv-markers-button', true);
    

    this.show()

  }
  
  destroy() {
    // do your cleanup logic here
    
    super.destroy();
  }

  onClick() {
    console.log('on lcick');
    this.toggleActive();

  }
  
  
}

import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { MyFirebase } from '../firebase';

import { Utils } from '../utils';

import { BehaviorSubject } from 'rxjs';

const myFirebase = MyFirebase.getInstance();
const utils = Utils.getInstance();


export class MyAuth {
    private static instance: MyAuth; 
    public auth: any;
    public user$: BehaviorSubject<any>;    

    constructor() {
      this.auth = getAuth(myFirebase.defaultApp);
      this.user$ = new BehaviorSubject(this.auth.currentUser);

        let btn = document.getElementById("btn-auth-signin-google");
        btn.addEventListener("click", (e:Event) => this.signIn());

        let signOutBtn = document.getElementById("signOutBtn");
        signOutBtn.addEventListener("click", (e:Event) => this.signOut());


        this.auth.onAuthStateChanged((user:any) => {
          if (user) {
            // User logged in already or has just logged in.
            console.log(user.uid);
            this.user$.next(user);
          } else {
            console.log('not logged in');
            this.user$.next(null);
          }
        });
        
    }

    public static getInstance(): MyAuth {
        if (!MyAuth.instance) {
            MyAuth.instance = new MyAuth();
        }
  
        return MyAuth.instance;
      }

    signIn() {
        
        const provider = new GoogleAuthProvider();

        signInWithPopup(this.auth, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            this.user$.next(user);

            console.log('goint to tour')
          }).catch((error) => {
            console.log(error.message)
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            this.user$.next(null);
          })  
    }

    signOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log('signed out')
      }).catch((error) => {
        console.log(error.message)
      });
  }  
       
} 